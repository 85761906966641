<template>
  <ion-page>
    <Header title="Hadiah Saya" />
    <ion-content :fullscreen="true">
      <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>
      <div class="container">
        <template v-for="i in items" :key="i.id" >
          <Coupon :coupon="i.coupon" @open="detail(i)">
            <ion-badge :color="color[i.status]">{{ status[i.status] }}</ion-badge>
          </Coupon>
        </template>
      </div>
      <ion-infinite-scroll
        @ionInfinite="load"
        threshold="100px"
        id="infinite-scroll"
        :disabled="isDisabled"
      >
        <ion-infinite-scroll-content
          loading-spinner="bubbles"
          loading-text="Loading more data...">
        </ion-infinite-scroll-content>
      </ion-infinite-scroll>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage, IonInfiniteScroll, IonInfiniteScrollContent, IonRefresher, IonRefresherContent, IonBadge } from '@ionic/vue';
import Header from '@/components/headerPage'
import Coupon from '@/components/coupon'

export default {
  components: {
    IonContent,
    IonPage,
    Header,
    Coupon,
    IonBadge,
    IonInfiniteScroll, IonInfiniteScrollContent, IonRefresher, IonRefresherContent
  },
  data () {
    return {
      items: [],
      isDisabled: false,
      page: 1,
      status: ["Di Batalkan", "Sedang Diproses", "Di Tunda", "Berhasil"],
      color: ["danger", "primary", "warning", "success"]
    }
  },
  created () {
    this.load()
  },
  methods: {
    async load (e) {
      let data = await this.$api.get('/api/claim', {params: { page: this.page }})

      if(data.meta.last_page == this.page) {
        this.isDisabled = true;
      }

      data.data.forEach(c => {
        this.items.push(c)
      })
      if(e && e.type == 'ion-infinite')
        e.target.complete();

      this.page++
    },
    async doRefresh($event) {
      this.page = 1;
      this.items = []
      this.isDisabled = false
      await this.load()
      $event.target.complete();
    },
    detail(c) {
      this.$router.push({
        name: 'ClaimStatus',
        params: { id: c.id }
      })
    }
  }
}
</script>

<style>

</style>
